@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageWalrus {
  svg {
    width: 171px;
    margin-right: 6rem;
  }
  @include media-breakpoint-up('lg') {
    width: 271px;
    margin-right: 9rem;
  }
}

.imageGiraffe {
  margin-top: 2.5rem;

  svg {
    width: 109px;
    margin-top: -2rem;
    margin-right: -3rem;
    @include media-breakpoint-up('lg') {
      width: 209px;
      margin-top: -3rem;
      margin-right: -4rem;
    }
  }
}

.imageAntilope {
  margin-top: 2.5rem;

  svg {
    width: 116px;
    margin-top: -2rem;
    margin-right: -3rem;

    @include media-breakpoint-up('lg') {
      width: 216px;
      margin-top: -3rem;
      margin-right: -4rem;
    }
  }
}

.imageCrocodile {
  svg {
    width: 152px;
    margin-top: -1rem;
    margin-right: 5rem;
    @include media-breakpoint-up('lg') {
      width: 252px;
      margin-right: 7rem;
    }
  }
}

.imageElephant {
  svg {
    width: 152px;
    margin-right: 6rem;
    @include media-breakpoint-up('lg') {
      width: 282px;
      margin-right: 10rem;
    }
  }
}

.imageSloth {
  margin-top: 5rem;

  svg {
    width: 150px;
    margin-top: -5rem;
    margin-right: -6rem;
    @include media-breakpoint-up('lg') {
      width: 299px;
      margin-top: -8rem;
      margin-right: -10rem;
    }
  }
}

.icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: auto;
    height: 40px;
  }
  @include media-breakpoint-up('lg') {
    svg {
      width: 100%;
    }
  }
}

.label {
  font-size: 1.5rem;
  text-align: center;
  margin-top: 1rem;
  min-height: 150px;
  padding: 0 2rem;
}