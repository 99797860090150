@import '../../../assets/styles/stylekit';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';

.container {
  position: relative;
  margin-top: 7rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;


  h2 {
    font-size: 2rem;
    max-width: 500px;
    @include media-breakpoint-up('lg') {
      font-size: 3.9rem;
    }
  }
}

.crocodile {
  svg {
    height: 60%;
    width: 244px;
  }
}

.sloth {
  margin-top: 1rem;

  svg {
    height: 60%;
    width: 244px;
  }
}


.textStroke {
  background-image: url("/statics/svgs/textHighlight-blue.svg");
  padding: 2px 7px;
  white-space: nowrap;
  color: $quizado-primary;
}

.list {
  font-size: 2rem;
}