@import '../../../assets/styles/stylekit';
@import '../../../assets/styles/variables';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';

.title {
  font-size: 2rem;
  @include media-breakpoint-up('lg') {
    font-size: 3.9rem;
  }
}